import { CalendarOutlined, EditOutlined } from '@ant-design/icons';
import { IconArrowsLeftRight } from '@tabler/icons';
import { Button, Col, List, Row, Space, Tooltip } from 'antd';
import Can from 'casl/can';
import * as CASL from 'casl/constants';
import OBJECTS from 'casl/objects';
import { format } from 'date-fns';
import { Formik } from 'formik';
import { Checkbox, DatePicker, Form, InputNumber, ResetButton, SubmitButton } from 'formik-antd';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { currencyExchangeActions } from 'store/currency-exchange/currency-exchange.slice';
import { exchangeScheme } from 'views/currency-exchange/constants';
import { useSelector } from 'react-redux';
import moment from 'moment';
export const HistoryListItem = ({ exchange, currencies }) => {
  const dispatch = useDispatch();

  const [isEditing, setEditing] = useState(false);

  const handleFormSubmit = (values, actions) => {
    dispatch(currencyExchangeActions.editExchange(values));
    actions.setSubmitting(false);
    setEditing(false);
  };

  let Day_of_Week=useSelector((state)=>{
    return state.appConfiguration?.dow
  })

  if(Day_of_Week===undefined || Day_of_Week===null || Day_of_Week===''){
    Day_of_Week=1;
  }

  moment.locale('en-gb',{
    week:{
      dow:Day_of_Week
    }
  })

  return (
    <List.Item
      style={{ backgroundColor: exchange.isActive ? '#ffeeb5' : 'inherit' }}
      actions={
        !isEditing &&
        Can(CASL.CASL_UPDATE, OBJECTS({}).VARIOUS_GROUP_SETTINGS.childrenObjects.CURRENCY_EXCHANGE.id) && [
          <Button onClick={() => setEditing(true)} type="link" key={1}>
            <EditOutlined />
          </Button>
        ]
      }
    >
      {!isEditing ? (
        <List.Item.Meta
          title={
            <Tooltip placement="right" title="Exchange Rate">
              <Space size={5}>
                <IconArrowsLeftRight width={13} height={13} />
                {exchange.conversionRate}
              </Space>
            </Tooltip>
          }
          description={
            <Tooltip placement="right" title="Valid from">
              <Space size={5}>
                <CalendarOutlined width={13} height={13} /> {format(new Date(exchange.date), 'dd-MM-yyyy')}
              </Space>
            </Tooltip>
          }
        />
      ) : (
        <Formik
          onSubmit={handleFormSubmit}
          validateOnChange
          validationSchema={exchangeScheme}
          initialValues={{ isActive: false, ...exchange }}
        >
          {() => (
            <Form style={{ width: '100%' }} layout="vertical">
              <Row gutter={[10]}>
                <Col span={12}>
                  <Form.Item label="Rate" name="conversionRate">
                    <InputNumber placeholder="Rate" style={{ width: '100%' }} name="conversionRate" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Valid From" name="date">
                    <DatePicker format={'DD-MM-yyyy'} style={{ width: '100%' }} name="date" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name="isActive">
                <Checkbox name="isActive">Is Active</Checkbox>
              </Form.Item>
              <Space size={10} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={() => setEditing(false)} type="danger" ghost>
                  Cancel
                </Button>
                <ResetButton>Reset</ResetButton>
                <SubmitButton>Submit</SubmitButton>
              </Space>
            </Form>
          )}
        </Formik>
      )}
    </List.Item>
  );
};

HistoryListItem.propTypes = {
  exchange: PropTypes.object,
  currencies: PropTypes.array
};

//   (
//     <List.Item
//       key={history.id}
//       title={history.conversionRate}
//       actions={[

//       ]}
//     >

//     </List.Item>
//   ))
// ) : (
