import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { timesheetActions } from 'store/time-sheet/time-sheet.slice';

export const useFetchWeekByDate = (employeeId, date,isDraft) => {
  const dispatch = useDispatch();

  useEffect(() => {



    if(isDraft){
      dispatch(timesheetActions.ImpersonategetDaysByDate({ date, employeeId }));
    }else{
    dispatch(timesheetActions.getDaysByDate({ date, employeeId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }}, [date, employeeId]);
};
