import React from 'react';
import { Button, Modal, Card, Col, Descriptions, Row, Typography, Tag, Space, Tooltip } from 'antd';
import { IconCalendarStats, IconClockHour3 } from '@tabler/icons';

const { Text } = Typography;

const WeekdayModal = (props) => {
  let { popup, Setpopup, day_hour_pop_up } = props;
let value=[];
if(day_hour_pop_up!= null && day_hour_pop_up!=undefined){
value=day_hour_pop_up.timesheetSettingDays;
if(value===null || value===undefined){
    value=[];
}
}
  return (
    <div className='Main_body'>
      <Modal title="Hours Per day" open={popup} onOk={() => Setpopup(false)} onCancel={() => Setpopup(false)}  cancelButtonProps={{ style: { display: 'none' } }}  okText="Close" width={'28.125rem'} >
        <Card size="small" hoverable>
          <Row>
            {value.map((day, index) => (
              <Col key={day.id} span={24} style={{ backgroundColor: '#FAFAFA' }}>
                <Descriptions size="small" layout="horizontal" bordered>
                  <Descriptions.Item
                    label={
                      <Text strong>
                        {day.weekDay.description}
                        <Tag color="blue" style={{ float: 'right' ,width: '60px' }}>
                          <Tooltip title="Working Hours">
                            <Space size={3} style={{display:'flex', justifyContent:'center'}} align="baseline">
                              <IconClockHour3 style={{ display: 'block' }} size={13} height={12} />
                              {day.hours}
                            </Space>
                          </Tooltip>
                        </Tag>
                      </Text>
                    }
                  />
                </Descriptions>
              </Col>
            ))}
          </Row>
        </Card>
      </Modal>
    </div>
  );
};

export default WeekdayModal;
