import { Space, Typography } from "antd";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { timesheetServices } from "store/time-sheet/time-sheet.services";
import { BackLink } from "ui-component";
import { weekDaysEnum } from "views/timesheet/consts";
import { EmployeeTimesheetWithDays } from "../../components";
import { LineManagerFooter } from "../../components/line-manager-footer";
import { useFetchWeekByWeekId } from "../../hooks";
import { getWeekInfo } from "../../utils";
import { timesheetActions } from "store/time-sheet/time-sheet.slice";

const today = format(new Date(), "yyyy-MM-dd");

export const ManagerTimesheet = () => {
  const {
    state: { employee, weekId, contractType }
  } = useLocation();
  const dispatch = useDispatch();
  useFetchWeekByWeekId(employee?.id, weekId);

  const timesheetSettings = useSelector((state) => state.timesheetSettings.myTimesheetSettings);

  const [weekSettings, setWeekSettings] = useState(
    Array(7)
      .fill()
      .map((item, index) => ({
        isOpen: index < contractType?.defaultWorkingDaysCount ? true : false,
        weekDay: weekDaysEnum[index]
      }))
  );

  const getSettings = async () => {
    const thisWeek = await timesheetServices.getDaysByDate({ date: today, employeeId: employee?.id });
    const thisWeekId = thisWeek.data.data.weekId;

    switch (weekId) {
      case thisWeekId:
        return setWeekSettings(timesheetSettings.CURRENT_WEEK.timesheetSettingDays);
      case thisWeekId - 1:
        return setWeekSettings(timesheetSettings.PREVIOUS_WEEK.timesheetSettingDays);
      case thisWeekId + 1:
        return setWeekSettings(timesheetSettings.NEXT_WEEK.timesheetSettingDays);
      default:
        return setWeekSettings(timesheetSettings.CURRENT_WEEK.timesheetSettingDays);
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  const singleApproval = useSelector((state) => state.appConfiguration.timesheetAutoReview);

  const dayTargetHours = contractType?.hoursPerDay || 0;

  const week = useSelector((state) => state.timesheet.days);
  const isInOvertime = week?.isInOvertime ?? false;

  const weekInfo = getWeekInfo(weekSettings, isInOvertime, dayTargetHours);
  const ModalType = "LineManager";
  return (
    <EmployeeTimesheetWithDays
      employeeId={employee?.id}
      footer={<LineManagerFooter />}
      editable={false}
      pdCommentShown={!singleApproval}
      dayTargetHours={dayTargetHours}
      weekInfo={weekInfo}
      tableTitle={
        <Space>
          <BackLink />
          <Typography.Text strong type="success">
            Approving For:
          </Typography.Text>
          <Typography.Text strong>{employee?.fullName}</Typography.Text>
        </Space>
      }
      ModalType={ModalType}
      ContractType={contractType}
    />
  );
};
