import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const timesheetServices = {
  submitTimesheet(timesheet) {
    return axios.post(API_PATH + '/timesheetEntry/submit', timesheet);
  },
  deleteTimesheet(payload) {
    return axios.delete(API_PATH + '/timesheetEntry/' + payload.id);
  },
  getDaysByDate({ date, employeeId }) {
    return axios.get(`${API_PATH}/week/daysByDate/${date}/${employeeId || ''}`);
  },
  getDaysByWeekId({ weekId, employeeId }) {
    return axios.get(`${API_PATH}/week/daysByWeek/${weekId}/${employeeId || ''}`);
  },
  getDaysByWeeksId(ids) {
    return Promise.all(ids.map((id) => axios.get(API_PATH + '/week/daysByWeek/' + id)));
  },
  getMyTimeSheet() {
    return axios.get(API_PATH + `/timesheetEntry/getAllByEmployeeId`);
  },
  getUserTimeSheetsForMaster(id) {
    return axios.get(API_PATH + `/timesheetEntry/getUserTimesheetsMaster?employeeId=${id}`);
  },
  getTeamPendingTimeSheet() {
    return axios.get(API_PATH + `/timesheetEntry/getAllByManagerIdPending`);
  },
  getProjectTeamTimeSheetsAll(projectId) {
    return axios.get(API_PATH + '/timesheetEntry/getPreviousCurrentWeekProjectTeamByProjectId/' + projectId);
  },
  getTimeSheetByUserAndWeek(value) {
    return axios.get(API_PATH + `/timesheetEntry/byWeekAndEmployee/${value.employeeId}/${value.weekId}`);
  },
  approveTimeSheet(payload) {
    return axios.post(API_PATH + `/timesheetEntry/managerDecision`, payload);
  },
  patchTimesheet(payload) {
    return axios.patch(API_PATH + `/timesheetEntry/edit?isMaster=${payload.isMaster || false}`, payload);
  },
  addNewTeamMemberTimesheet(newTimesheet) {
    return newTimesheet;
  },
  updateMyTimesheet(myTimesheet) {
    return myTimesheet;
  },
  totalhour(employeeId,today){
    return axios.get(API_PATH + '/timesheetEntry/get/' + employeeId+'/'+ today )
  },
  empManHours(employeeId){
    return axios.get(API_PATH + '/embd/empid/'+employeeId);
  },
  ImpersonategetDaysByDate({ date, employeeId }) {
    return axios.get(`${API_PATH}/week/daysByDateImpersonateOnly/${date}/${employeeId || ''}`);
  }
};
