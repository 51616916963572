import { Card, Col, Row, Space, Typography, Progress, Alert, Button } from "antd";
import { useSelector } from "react-redux";
import { useTimesheet } from "../../hooks";
import { useState } from "react";
import { IconCalendarStats, IconClockHour3 } from "@tabler/icons";
import WeekdayModal from "views/app-settings/timesheet-settings/components/WeekdayModal/weekdayModal";
const { Text } = Typography;
import { Tooltip } from "antd";

export const HoursNotification = ({ ModalType, ContractType }) => {
  const isInOvertime = useSelector((state) => state.timesheet.days)?.isInOvertime;
  const [popup, Setpopup] = useState(false);
  const { calculateWeekHours, weekTargetHours, dayTargetHours } = useTimesheet();
  const { totalHours } = calculateWeekHours();

  let day_hour_pop_up = useSelector((state) => state.timesheetSettings.myTimesheetSettings.CURRENT_WEEK);

  let Master_value = useSelector((state) => state.timesheetSettings.masterTimesheetSettings);
  const contractList = useSelector((state) => state.contractTypes.all);
  let impersonate_value = useSelector((state) => state.timesheetSettings.impersonateTimesheetSettings);
  if (ModalType === "Impersonate") {
    day_hour_pop_up = impersonate_value;
  } else if (ModalType === "Master") {
    day_hour_pop_up = Master_value;
  } else if (ModalType === "LineManager") {
    if (ContractType != null && ContractType != undefined) {
      const contract = contractList.find((ctc) => ctc.id === ContractType.id);
      let day = {};
      let weekDay = {};
      let description = {};
      let timesheetSettingDays = [];

      for (let i = 1; i < 8; i++) {
        day = {};
        day.weekDay = {};
        day.weekDay.description = {};
        if (i === 1) {
          day.hours = contract.monday;
          day.weekDay.description = "Monday";
          day.id = i;
        } else if (i === 2) {
          day.hours = contract.tuesday;
          day.weekDay.description = "Tuesday";
          day.id = i;
        } else if (i === 3) {
          day.hours = contract.wednesday;
          day.weekDay.description = "Wednesday";
          day.id = i;
        } else if (i === 4) {
          day.hours = contract.thursday;
          day.weekDay.description = "Thursday";
          day.id = i;
        } else if (i === 5) {
          day.hours = contract.friday;
          day.weekDay.description = "Friday";
          day.id = i;
        } else if (i === 6) {
          day.hours = contract.saturday;
          day.weekDay.description = "Saturday";
          day.id = i;
        } else if (i === 7) {
          day.hours = contract.sunday;
          day.weekDay.description = "Sunday";
          day.id = i;
        }

        timesheetSettingDays.push(day);
      }
      const weekDaysFormat = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

      let indexOfDay = weekDaysFormat.indexOf(contract.startOfTheWeek);
      if (indexOfDay === -1) {
        indexOfDay = 0;
      }
      let daysarr = [];
      for (let i = indexOfDay; i < 7; i++) {
        daysarr.push(timesheetSettingDays[i]);
      }

      for (let i = 0; i < indexOfDay; i++) {
        daysarr.push(timesheetSettingDays[i]);
      }

      day_hour_pop_up = {
        ...day_hour_pop_up,
        timesheetSettingDays: daysarr
      };
    }
  }

  return (
    <>
      {popup && <WeekdayModal popup={popup} Setpopup={Setpopup} day_hour_pop_up={day_hour_pop_up} />}

      <Row gutter={[16, 16]} align={"middle"}>
        {isInOvertime && (
          <Col>
            <Alert
              description={
                <>
                  <Text type="secondary">Overtime is enabled</Text>
                  <p style={{ margin: 0 }} >
                    Extra hours: <strong>{totalHours - weekTargetHours < 0 ? 0 : totalHours - weekTargetHours}</strong>
                  </p>
                </>
              }
            ></Alert>
          </Col>
        )}
        <Col>
          <Card
            style={{
              border: "1px solid #e02c2c",
              padding: 0,
              borderRadius: 4
              
            }}
            bodyStyle={{ padding: 5 }}
            className={"targetHoursAlert"}
          >
            <Space direction="vertical" size={[0, 0]}>
              <Text strong>
                Weekly Target Hours: <Text type="danger">{weekTargetHours}</Text>
              </Text>

              <Text style={{ display: "flex",fontWeight:'600'}}>
                Daily Target Hours :
                <Text strong style={{ marginTop: "3px" }} type="info">
                  <Tooltip title="Click here to view daily target hours">
                    <IconClockHour3 color="blue" size={20} height={18} onClick={() => Setpopup(true)} className="Clock"/>
                  </Tooltip>
                </Text>
              </Text>
            </Space>
          </Card>
        </Col>
        <Col>
          <Progress
            type="circle"
            width={70}
            percent={(totalHours * 100) / weekTargetHours}
            status={totalHours <= weekTargetHours ? (totalHours < weekTargetHours ? "active" : "success") : "exception"}
            format={() => `${totalHours} H`}
          />
        </Col>
      </Row>
    </>
  );
};
