import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const contractTypesServices = {
  getContractTypesAll() {
    return axios.get(API_PATH + '/contractType');
  },
  postContractType(payload) {
    return axios.post(API_PATH +'/contractType',payload);
  },
  patchContractType(payload) {
    return axios.patch(API_PATH+'/contractType', payload);
  },
  deleteContractType(payload) {
    return axios.delete(API_PATH + '/contractType', { headers: {}, data: { id: payload } });
  }
};
