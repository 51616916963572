import { Button, Col, Row, Popconfirm } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { timesheetActions } from "store/time-sheet/time-sheet.slice";
import { useTimesheet } from "../../hooks";
import { checkEmployeeTimesheetEditable, mergeDaysArray } from "../../utils";
import { LineManagerComment } from "../line-manager-comment";
import { Modal, Space } from "antd";
import { timesheetServices } from "store/time-sheet/time-sheet.services";
import { useEffect } from "react";
import { array } from "prop-types";
export const EmployeeButtons = ({ weekSettings, withDraft = false, employeeId }) => {
  const monthsarr = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  let flag = false;
  const value = useSelector((state) => state.appConfiguration.moduleEnabledTimeBudget);
  //const EmployeeID = useSelector((state) => state.currentUser.user.id);
  let EmployeeID = employeeId;

  const fetchDate = useSelector((state) => state.timesheet.days?.days[0]?.theDate);
  console.log('fetchDate',fetchDate);
  const dispatch = useDispatch();
  const data = async () => {
    try {
      //dispatch(timesheetActions.totalhour());
      //debugger
      if (fetchDate != undefined && fetchDate != null) {
        const response = await timesheetServices.totalhour(EmployeeID, fetchDate);
        const data = response.data;
        dispatch(timesheetActions.totalhourFulfilled(data));
      }
    } catch (error) {
      console.log("error fetching data of total hours", error);
    }
  };
  const getEmpHour = async () => {
    try {
      //dispatch(timesheetActions.totalhour());

      const value = await timesheetServices.empManHours(EmployeeID);
      dispatch(timesheetActions.empManHoursFulfilled(value.data));
    } catch (error) {
      console.log("error fetching data of total hours", error);
    }
  };

  const { timesheet, formRef } = useTimesheet();
  const totalconsumedHour = useSelector((state) => state.timesheet.totalhour);
  const empHour = useSelector((state) => state.timesheet.empManHours);
  console.log("totalconsumedHour", totalconsumedHour);
  console.log("emphour", empHour);

  let errorArr = [];

  const navigate = useNavigate();

  const week = useSelector((state) => state.timesheet.days);

  const handleCancelClick = () => navigate(-1);

  const handleResetClick = () => {
    formRef.current.resetForm();
  };

  const submitForm = (isDraft) => {
    const timeSheetEntryPerActivities = formRef.current.values.timeSheetEntryPerActivities.map((entryPerActivity) => {
      const newObject = {
        ...entryPerActivity,
        activityId: entryPerActivity.activityCascader?.[1],
        comments: entryPerActivity.comments || "",
        timesheetEntry: timesheet?.id,
        dayActivityLinks: mergeDaysArray([
          week.days.map((day) => ({ date: day.theDate })),
          entryPerActivity.dayActivityLinks
        ]).map((day) => ({ ...day, timeSheetPerActivityId: entryPerActivity.id }))
      };

      console.log("data for employee", newObject);
      return newObject;
    });

    const timesheetToSubmit = {
      ...timesheet,
      employeeId,
      weekId: week.weekId,
      isDraft,
      isMaster: false,
      timeSheetEntryPerActivities
    };

    let isvalid = false;

    if (isDraft) {
      isvalid = true;
    } else {
      if (value == true) {
        isvalid = ConsumedHours(timeSheetEntryPerActivities);
      } else {
        isvalid = true;
      }
    }
    if (isvalid) {
      if (!timesheet) {
        dispatch(timesheetActions.submitTimesheet(timesheetToSubmit));
      } else {
        dispatch(timesheetActions.patchTimesheet(timesheetToSubmit));
      }
    } else {
      let errorSet = [...new Set(errorArr)];
      diplayError(errorSet);
    }
  };

  function ConsumedHours(timeSheetEntryPerActivities) {
    debugger;
    //console.log('chala raha h consumed hours',timeSheetEntryPerActivities)
    let isvalid = true;
    errorArr = [];
    let mapDataTotalHours = new Map();
    let mapDataTotalHoursyearly = new Map();
    for (let i = 0; i < timeSheetEntryPerActivities.length; i++) {
      let projectId = timeSheetEntryPerActivities[i].projectId;
      let subProjectID = timeSheetEntryPerActivities[i].activityCascader[0];

      let dayLinkActivity = timeSheetEntryPerActivities[i].dayActivityLinks;
      for (let j = 0; j < dayLinkActivity.length; j++) {
        let obj = dayLinkActivity[j];
        let date = obj.date;
        var dateObject = new Date(date);
        let month = dateObject.getMonth() + 1;
        let key = projectId + "_" + subProjectID + "_" + dateObject.getFullYear() + "_" + month;
        let key1 = projectId + "_" + subProjectID + "_" + dateObject.getFullYear();
        if (mapDataTotalHours.has(key)) {
          let temp = mapDataTotalHours.get(key);
          temp = temp + obj.manHours;
          mapDataTotalHours.set(key, temp);
        } else {
          mapDataTotalHours.set(key, obj.manHours);
        }
        if (mapDataTotalHoursyearly.has(key1)) {
          let temp = mapDataTotalHoursyearly.get(key1);
          temp = temp + obj.manHours;
          mapDataTotalHoursyearly.set(key1, temp);
        } else {
          mapDataTotalHoursyearly.set(key1, obj.manHours);
        }
      }
    }

    //   filterpostdata = postdata[i].empManHours.filter((yy) => {
    //     return yy.id !== null ;
    // });

    mapDataTotalHours.forEach(function (value, key) {
      console.log(key + " = " + value);
      let arrKey = key.split("_");
      let projectid = arrKey[0];
      let subProjectId = arrKey[1];
      let year = arrKey[2];
      let month = arrKey[3];
      let projectName = "";
      let subProjectName = "";
      let filteremphour = empHour.filter((yy) => {
        return yy.projectId == projectid && yy.subProjectId == subProjectId;
      });
      if (
        filteremphour != null &&
        filteremphour != undefined &&
        filteremphour.length > 0 &&
        filteremphour[0].isEnabled == true
      ) {
        let empHourData;
        let filterempHourData;
        if (filteremphour.length > 0) {
          empHourData = filteremphour[0];
          projectName = empHourData.projectName;
          subProjectName = empHourData.subProjectName;
          filterempHourData = empHourData.empManHours.filter((yy) => {
            return yy.year == year;
          });
        }

        //need to check this
        let filtertotalconsumedHour = totalconsumedHour.filter((yy) => {
          return yy.project_id == projectid && yy.subProject_id == subProjectId;
        });
        let consumedHourData;
        let filterconsumedHourData;
        if (filtertotalconsumedHour.length > 0) {
          consumedHourData = filtertotalconsumedHour[0];
          filterconsumedHourData = consumedHourData.hours.filter((yy) => {
            return yy.year == year;
          });
        }

        if (filterempHourData !== null && filterempHourData !== undefined && filterempHourData.length > 0) {
          if (readyearly(filterempHourData)) {
            let objConsumedHOurs = 0;
            if (
              filterconsumedHourData !== null &&
              filterconsumedHourData !== undefined &&
              filterconsumedHourData.length > 0
            ) {
              objConsumedHOurs = filterconsumedHourData[0].consumedHours;
            }
            let maxhours = filterempHourData[0].totalHours;
            let mapid = projectid + "_" + subProjectId + "_" + year;
            let filledhour = mapDataTotalHoursyearly.get(mapid);
            if (objConsumedHOurs === null || objConsumedHOurs === undefined) {
              objConsumedHOurs = 0;
            }

            if (filledhour > maxhours - objConsumedHOurs) {
              isvalid = false;
              let remainingHours = maxhours - objConsumedHOurs;
              //alert("Yearly Hours limit exceed can not submit");
              errorArr.push(
                "For project *" +
                  projectName +
                  "* ,subproject *" +
                  subProjectName +
                  "* and year *" +
                  year +
                  "* hours limit exceeded. Available hours *" +
                  remainingHours +
                  "*."
              );
            }
          } else {
            let objConsumedHOurs = 0;
            if (
              filterconsumedHourData !== null &&
              filterconsumedHourData !== undefined &&
              filterconsumedHourData.length > 0
            ) {
              objConsumedHOurs = monthHours(filterconsumedHourData[0], month);
            }
            let mapid = projectid + "_" + subProjectId + "_" + year + "_" + month;
            let filledhour = mapDataTotalHours.get(mapid);

            let maxhours = monthHours(filterempHourData[0], month);
            if (filledhour > maxhours - objConsumedHOurs) {
              isvalid = false;
              let remainingHours = maxhours - objConsumedHOurs;
              //alert("Month hours for"+ year+"_"+month +  " Hours limit exceed can not submit");
              errorArr.push(
                "For project *" +
                  projectName +
                  "* ,subproject *" +
                  subProjectName +
                  "*  and *" +
                  monthsarr[month - 1] +
                  " " +
                  year +
                  "* hours limit exceeded. Available hours *" +
                  remainingHours +
                  "*."
              );
            }
          }
        }
      }
    });

    return isvalid;
  }

  function readyearly(filterempHourData) {
    let obj = filterempHourData[0];
    let readyearly = true;

    if (obj.jan !== 0 && obj.jan != null && obj.jan !== undefined) {
      readyearly = false;
    }
    if (obj.feb !== 0 && obj.feb != null && obj.feb !== undefined) {
      readyearly = false;
    }
    if (obj.mar !== 0 && obj.mar != null && obj.mar !== undefined) {
      readyearly = false;
    }
    if (obj.apr !== 0 && obj.apr != null && obj.apr !== undefined) {
      readyearly = false;
    }
    if (obj.may !== 0 && obj.may != null && obj.may !== undefined) {
      readyearly = false;
    }
    if (obj.jun !== 0 && obj.jun != null && obj.jun !== undefined) {
      readyearly = false;
    }
    if (obj.jul !== 0 && obj.jul != null && obj.jul !== undefined) {
      readyearly = false;
    }
    if (obj.aug !== 0 && obj.aug != null && obj.aug !== undefined) {
      readyearly = false;
    }
    if (obj.sep !== 0 && obj.sep != null && obj.sep !== undefined) {
      readyearly = false;
    }
    if (obj.nov !== 0 && obj.nov != null && obj.nov !== undefined) {
      readyearly = false;
    }
    if (obj.dec !== 0 && obj.dec != null && obj.dec !== undefined) {
      readyearly = false;
    }
    if (obj.oct !== 0 && obj.oct != null && obj.oct !== undefined) {
      readyearly = false;
    }

    return readyearly;
  }

  function monthHours(monthObject, month) {
    let obj = monthObject;
    let hours = 0;
    if (month == 1) {
      hours = obj.jan;
    } else if (month == 2) {
      hours = obj.feb;
    } else if (month == 3) {
      hours = obj.mar;
    } else if (month == 4) {
      hours = obj.apr;
    } else if (month == 5) {
      hours = obj.may;
    } else if (month == 6) {
      hours = obj.jun;
    } else if (month == 7) {
      hours = obj.jul;
    } else if (month == 8) {
      hours = obj.aug;
    } else if (month == 9) {
      hours = obj.sep;
    } else if (month == 10) {
      hours = obj.oct;
    } else if (month == 11) {
      hours = obj.nov;
    } else if (month == 12) {
      hours = obj.dec;
    }

    return hours;
  }

  const handleTimesheetSubmitWithDraft = () => {
    const timeSheetEntryPerActivities = formRef.current.values.timeSheetEntryPerActivities.map(
      ({ projectId, activityCascader }) => ({ projectId, activityCascader })
    );

    formRef.current.validateForm({ timeSheetEntryPerActivities }).then((errors) => {
      delete errors.days;

      if (Object.keys(errors).length) {
        return formRef.current.setTouched(errors);
      }
      submitForm(true);
    });
  };

  const handleTimesheetSubmit = () => {
    formRef.current.validateForm().then((errors) => {
      if (Object.keys(errors).length) {
        return formRef.current.setTouched(errors);
      }
      submitForm(false);
    });
  };

  const diplayError = (data) => {
    Modal.error({
      title: "Unable to submit timesheet!",
      content: (
        <div>
          {data.map((i, index) => (
            <div key={index}>
              {" "}
              {index + 1}. {renderBoldText(i)}{" "}
            </div>
          ))}
          <div style={{ color: "#faad14" }}>For more information, please contact Project specific Project Manager.</div>
        </div>
      ),
      onOk() {}
    });
  };
  const renderBoldText = (text) => {
    try {
      const segments = text.split(/(\*.*?\*)/); // Split text based on '*'
      return segments.map((segment, index) => {
        if (segment.startsWith("*") && segment.endsWith("*")) {
          return <b key={index}>{segment.substring(1, segment.length - 1)}</b>;
        } else {
          return segment;
        }
      });
    } catch (error) {
      return text;
    }
  };
  useEffect(() => {
    data();
  }, [week]);
  useEffect(() => {
    getEmpHour();
  }, [EmployeeID]);

  return (
    <>
      <Space direction="vertical" style={{ display: "flex" }}>
        {timesheet && timesheet.lineManagerComments && <LineManagerComment editable={false} />}
        {checkEmployeeTimesheetEditable(timesheet, weekSettings) && (
          <Row gutter={[16, 0]} justify={"end"}>
            <Col span={2}>
              <Popconfirm
                title="By cancelling, you will lose your changes, sure to cancel?"
                onConfirm={handleCancelClick}
              >
                <Button block danger ghost>
                  Cancel
                </Button>
              </Popconfirm>
            </Col>
            <Col onClick={handleResetClick} span={2}>
              <Button type="default" block>
                Reset
              </Button>
            </Col>
            {withDraft && (
              <Col span={3}>
                <Popconfirm
                  title="Keep in mind that this will only save your timesheet without submitting it for review, are you sure you want to save it as draft?"
                  onConfirm={handleTimesheetSubmitWithDraft}
                  placement="topRight"
                >
                  <Button block ghost type="primary">
                    Save and submit later
                  </Button>
                </Popconfirm>
              </Col>
            )}
            <Col span={3}>
              <Popconfirm
                title="Are you sure you want to submit this timesheet for manager review?"
                onConfirm={handleTimesheetSubmit}
                placement="topRight"
              >
                <Button block type="primary">
                  Submit
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        )}
      </Space>
    </>
  );
};
