import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  all: []
};

export const selectContractTypeById = (state, id) => {
  const contractType = state.contractTypes.all.find((contractType) => contractType.id === id);
  return contractType;
};

export const { actions: contractTypesActions, reducer: contractTypesReducer } = createSlice({
  name: 'contractTypes',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getContractTypesAll(state) {
      state.loading = true;
    },
    getContractTypesAllFulfilled(state, action) {
      state.all = action.payload;
    },
    postContractType(state) {
      state.loading = true;
    },
    postContractTypeFulfilled(state, action) {
      state.loading=false;
      state.all = [...state.all, action.payload].sort((a, b) => a.name.localeCompare(b.name));
    },
    patchContractType(state) {
      state.loading = true;
    },
    patchContractTypeFulfilled(state, action) {
      const arr = [...state.all];
      const foundIndex = arr.findIndex((item) => item.id === action.payload.id);
      arr.splice(foundIndex, 1, action.payload);
      state.all = arr.sort((a, b) => a.name.localeCompare(b.name));
    },
    deleteContractType(state) {
      state.loading = true;
    },
    deleteContractTypeFulfilled(state, action) {
      state.all = state.all.filter((d) => d.id !== action.payload);
    }
  }
});
